import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
import { RouterProvider } from 'react-router';
import { createBrowserRouter } from 'react-router-dom';
import Error from './components/Error';
import Home from './components/Home';
import Services from './components/Services';
import About from './components/About';
import Lookbook from './components/Lookbook';
import Contact from './components/Contact';
import EventCalligraphy from './components/EventCalligraphy';
import WeddingCalligraphy from './components/WeddingCalligraphy';
import Engraving from './components/Engraving';
import SubmitReview from './components/SubmitReview';

const router = createBrowserRouter([
    { 
        path: "/",
        element: <App />,
        errorElement: <Error />,
        children: [
            {
                path: "/about",
                element: <About />
            },
            {
                path: "/contact",
                element: <Contact />
            },
            {
                path: "/engraving",
                element: <Engraving />
            },
            {
                path: "/eventcalligraphy",
                element: <EventCalligraphy />
            },
            {
                path: "/",
                element: <Home />
            },
            {
                path: "/lookbook",
                element: <Lookbook />
            },
            {
                path: "/review",
                element: <SubmitReview />
            },
            {
                path: "/services",
                element: <Services />
            },
            {
                path: "/weddingcalligraphy",
                element: <WeddingCalligraphy />
            },
        ]
    },
  ]);

ReactDOM.createRoot(document.getElementById("root")).render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
