import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import ScrollToTop from './util/ScrollToTop';
import './App.scss';
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import HomepageImage from './img/homepage_5.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleUp } from '@fortawesome/free-solid-svg-icons';

const App = () => {
  const location = useLocation();

  const isHome = location.pathname === "/";

  return (
    <div className="App-background" style={isHome ? { backgroundImage: "url(" + HomepageImage + ")" } : {}}>
      <NavBar />
      <div className="App-content" style={isHome ? { paddingBottom: 0 } : { backgroundColor: "#FFFFFF" }}>
        <ScrollToTop>
          <Outlet />
        </ScrollToTop>
        <button className="scroll-top-button" onClick={() => document.documentElement.scrollTo(0, 0)}>
          <FontAwesomeIcon icon={faArrowCircleUp} size="2x" />
          </button>
      </div>
      <Footer />
    </div>
  );
}

export default App;
